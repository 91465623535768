<template>
	<div id="reset">
		修改密码
	</div>
</template>

<script>
	export default {

	}
</script>

<style lang="scss">
</style>
